.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header {
  margin-bottom: 30px;
}

h1 {
  color: #333;
}

h2 {
  color: #666;
}

.error {
  color: red;
  margin-bottom: 15px;
}

.loading {
  font-style: italic;
  color: #666;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
  max-width: 300px;
}

input, select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.analysis {
  text-align: left;
}

.analysis-section {
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.analytics {
  margin-top: 30px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

footer {
  margin-top: 30px;
  font-size: 14px;
  color: #666;
}